import ImpactFactorConfiguration from "../../interfaces/entities/ImpactFactorConfiguration";
import { TextField, Typography, RadioGroup, FormControlLabel, Radio, Paper, Checkbox, IconButton, Tooltip } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { RemoveCircle, DragHandle } from "@mui/icons-material";
import { Draggable } from "react-beautiful-dnd";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { store } from '../../redux/storeStates/store';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    radioButtonSetting: {
        marginLeft: '10px',
        marginBottom: '5px'
    }
  });

interface Props {
    impactFactorConfiguration: ImpactFactorConfiguration;
    editMode: boolean;
    index: number;
    onRemove: (fc: any) => void;
    onUpdate: (fc: any) => void;
}

const lockDraggableXAxis = (style: any) => {
    if (style && style.transform) {
        return {
            ...style,
            transform: style.transform !== undefined ? 'translate(0px,' + style.transform.split(',')[1] : null
        };
    }
    return style;
}

export const FactorItem: React.FC<Props> = (props) => {
    const classes = useStyles();
    const [value, setValue] = useState<number>(-1);
    const [impactFactorConfiguration, setImpactFactorConfiguration] = useState<ImpactFactorConfiguration>(props.impactFactorConfiguration);

    const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(Number(event.target.value));
    }

    const handleDisplayCommentChange = (event: ChangeEvent<HTMLInputElement>) => {
        setImpactFactorConfiguration({
            ...impactFactorConfiguration,
            commentVisible: Number(event.target.checked)
        });
    }

    const handleDisplayNameChange = (event: any) => {
        setImpactFactorConfiguration({
            ...impactFactorConfiguration,
            displayName: event.target.value
        })
    }

    useEffect((() => {
        props.onUpdate(impactFactorConfiguration);
    }),
        // eslint-disable-next-line
        [impactFactorConfiguration]);

    useEffect((() => {
        if (props.editMode)
            setValue(-1);
    }), [props.editMode]);

    return (
        <Draggable draggableId={props.impactFactorConfiguration.impactFactorConfigurationId} index={props.index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={lockDraggableXAxis(provided.draggableProps.style)}
                >
                    <div style={{ marginBottom: 16 }}>
                        <Paper style={{ padding: 8, display: "block", marginBottom: 16 }} elevation={snapshot.isDragging ? 10 : 3} >
                            {props.editMode ?
                                <div style={{ display: "table", width: "100%" }}>
                                    <div
                                        style={{ verticalAlign: "middle", display: "table-cell", paddingRight: 8, width: 0 }}
                                        {...provided.dragHandleProps}
                                    >
                                        <Tooltip title={store.getState().translation.dictionary["move"]}>
                                            <DragHandleIcon />
                                        </Tooltip>
                                    </div>

                                    <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                                        <Tooltip title={store.getState().translation.dictionary["remove"]}>
                                            <IconButton color="primary" component="span" style={{ float: "right" }} onClick={() => props.onRemove(impactFactorConfiguration)}>
                                                <RemoveCircle />
                                            </IconButton>
                                        </Tooltip>

                                        <Typography variant="body1"
                                            style={{ display: "inline" }}
                                        >
                                            {impactFactorConfiguration.impactFactor.name}
                                        </Typography>

                                        <TextField
                                            label={store.getState().translation.dictionary["displayName"]}
                                            value={impactFactorConfiguration.displayName}
                                            style={{ display: "flex" }}
                                            onChange={handleDisplayNameChange}
                                        />
                                        <FormControlLabel
                                            label={store.getState().translation.dictionary["displayCommentField"]}
                                            control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} color="secondary" checked={impactFactorConfiguration.commentVisible === 1} onChange={handleDisplayCommentChange} />}
                                            style={{ display: "block" }}
                                        />
                                    </div>
                                </div>
                                :
                                <div>
                                    <Typography variant="body1"
                                        style={{ display: "inline" }}
                                    >
                                        {impactFactorConfiguration.displayName}
                                    </Typography>

                                    <RadioGroup className={classes.radioButtonSetting} placeholder="Värde" value={value} onChange={handleValueChange} >
                                        <FormControlLabel value={2} control={<Radio />} label={store.getState().translation.dictionary["large"]} disabled={props.editMode} />
                                        <FormControlLabel value={1} control={<Radio />} label={store.getState().translation.dictionary["certain"]} disabled={props.editMode} />
                                        <FormControlLabel value={0} control={<Radio />} label={store.getState().translation.dictionary["none"]} disabled={props.editMode} />
                                    </RadioGroup>
                                    {
                                        !impactFactorConfiguration.commentVisible ? null :
                                            <TextField style={{ display: "flex" }}
                                                multiline
                                                label={store.getState().translation.dictionary["comment"]}
                                                disabled={props.editMode}
                                            />
                                    }
                                </div>
                            }


                        </Paper>
                    </div>
                </div>
            )}
        </Draggable>
    );
}
export default FactorItem;