import { ThemeProvider, Modal, Paper, Grid, TextField, Button, Typography, Checkbox, FormControlLabel, Switch, MenuItem, Box, Stack } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { ChangeEvent, FormEvent, FormEventHandler, useState } from "react";
import { useSelector } from "react-redux";
import { ActionTypes } from "../../redux/actionTypes";
import { store } from "../../redux/storeStates/store";
import { Store } from "../../redux/storeStates/StoreStates";
import defaultEntities from "../../utils/EntityUtils";
import { theme } from "../common/Theme";
import { FormTypes } from "../../interfaces/ui/Enums";
import { FormControl, InputLabel, Select } from "@mui/material";
import Routes from "../../data/Routes";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface Props {
    FormType: string
}

const modalStyle = makeStyles({
    root: {
        alignContent: "center",
        margin: "auto",
        marginLeft: "50%",
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "300px"
    },
    wrapper: {
        padding: "14px"
    },
    header: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    content: {
        paddingTop: "18px",
        paddingBottom: "18px",
    },
    footer: {
    },
    button: {
        paddingLeft: '5px',
        paddingRight: '5px',
        borderRadius: '5px',
        backgroundColor: '#555',
        color: '#fff',
        '&:hover': {
        backgroundColor: '#fff',
        color: '#555'}
    },
    gridList: {
        display: 'grid',
    },
    checkboxSetting: {
        marginLeft: '10px',
    }
})

export default function UserForm(props: Props) {
    if (props.FormType === FormTypes.NEW) {
        return NewForm();
    }
    else if (props.FormType === FormTypes.EDIT) {
        return EditForm();
    }
    else return <></>;
}

export function NewForm() {
    // const classes = useStyles();
    const units = useSelector((state: Store) => state.units);

    const classes = modalStyle(theme);
    const [newUser, setNewUser] = useState({ ...defaultEntities.defaultUser });
    const [formAutoClose, setModalAutoClose] = useState(true);

    const submit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        Routes.User.add(newUser);

        if (formAutoClose) {
            store.dispatch({
                type: ActionTypes.SET_SHOW_FORM,
                payload: { show: false, type: FormTypes.NEW }
            });
        }
        setNewUser({ ...defaultEntities.defaultUser });
    }

    return (
        <div>
            <ThemeProvider theme={theme}>
                <Modal
                    open
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={classes.root}
                >
                    <Paper className={classes.wrapper} elevation={3}>

                        <Grid container spacing={2} justifyContent="center">
                            <Grid item className={classes.header}>
                                <Typography>{store.getState().translation.dictionary["addUser"]}</Typography>
                            </Grid>
                            {/* <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={!formAutoClose}
                                            onChange={() => setModalAutoClose(!formAutoClose)}
                                        ></Switch>}
                                    label="Håll kvar fönster"
                                />
                            </Grid> */}
                        </Grid>

                        <form
                            className={classes.content}
                            onSubmit={(event) => submit(event)}
                        >
                            {/* {new userEle().forms.getFörnamn(newUser.firstName,(name:string) => {
                                let tempUser = { ...newUser };
                                tempUser.firstName = name;
                                setNewUser(tempUser);
                            })} */}
                            <TextField
                                required
                                margin="dense"
                                variant="outlined"
                                label={store.getState().translation.dictionary["firstName"]}
                                value={newUser.firstName}
                                onChange={(event) => {
                                    let tempUser = { ...newUser };
                                    tempUser.firstName = event.target.value;
                                    setNewUser(tempUser);
                                }}
                            />
                            <TextField
                                required
                                margin="dense"
                                variant="outlined"
                                label={store.getState().translation.dictionary["lastName"]}
                                value={newUser.lastName}
                                onChange={(event) => {
                                    let tempUser = { ...newUser };
                                    tempUser.lastName = event.target.value;
                                    setNewUser(tempUser);
                                }}
                            />
                            <TextField
                                required
                                margin="dense"
                                variant="outlined"
                                label={store.getState().translation.dictionary["email"]}
                                value={newUser.email}
                                onChange={(event) => {
                                    let tempUser = { ...newUser };
                                    tempUser.email = event.target.value;
                                    setNewUser(tempUser);
                                }}
                            />
                            <TextField
                                required
                                margin="dense"
                                variant="outlined"
                                label={store.getState().translation.dictionary["title"]}
                                value={newUser.workTitle}
                                onChange={(event) => {
                                    let tempUser = { ...newUser };
                                    tempUser.workTitle = event.target.value;
                                    setNewUser(tempUser);
                                }}
                            />
                            <FormControl fullWidth>
                                <InputLabel >{store.getState().translation.dictionary["unit"]}</InputLabel>
                                <Select
                                    // required
                                    MenuProps={{
                                        MenuListProps: {
                                        className: classes.gridList, // Apply styles to the ul element
                                        },
                                    }}                                    
                                    margin="dense"
                                    value={newUser.unit.id}
                                    label={store.getState().translation.dictionary["unit"]}
                                    onChange={(event) => {
                                        let tempUser = { ...newUser };
                                        tempUser.unit.id = event.target.value;
                                        tempUser.unit.name = units.find(x => x.unitId === tempUser.unit.id)?.name || "";
                                        setNewUser(tempUser);
                                    }}
                                >
                                    {units.filter(unit => unit.active === 1).sort((u1, u2)=> (u1.name > u2.name) ? 1 : (u2.name > u1.name) ? -1 : 0).map((unit) => (
                                        <MenuItem key={unit.unitId} value={unit.unitId} >
                                            {unit.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <br />
                            <FormControlLabel 
                                control={
                                    <Checkbox className={classes.checkboxSetting}
                                        checked={newUser.unitSensor ? true : false}
                                        icon={<CheckBoxOutlineBlankIcon color="action" />}
                                        checkedIcon={<CheckBoxIcon color="action" />}
                                        onChange={(event) => {
                                            let tempUser = { ...newUser };
                                            tempUser.unitSensor = event.target.checked ? 1 : 0;
                                            setNewUser(tempUser);
                                        }}
                                    />
                                }
                                label={store.getState().translation.dictionary["sensor"]}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox className={classes.checkboxSetting}
                                        checked={newUser.active ? true : false}
                                        icon={<CheckBoxOutlineBlankIcon color="action" />}
                                        checkedIcon={<CheckBoxIcon color="action" />}
                                        onChange={(event) => {
                                            let tempUser = { ...newUser };
                                            tempUser.active = event.target.checked ? 1 : 0;
                                            setNewUser(tempUser);
                                        }}
                                    />
                                }
                                label={store.getState().translation.dictionary["active"]}
                            />

                            <Stack sx={{ mt: 2 }} direction="row" justifyContent="center" spacing={2}>
                                <Button type="submit" variant="contained" className={classes.button}>
                                {store.getState().translation.dictionary["save"]}
                                </Button>

                                <Button onClick={() => {
                                    store.dispatch({
                                        type: ActionTypes.SET_SHOW_FORM,
                                        payload: { show: false, type: FormTypes.NEW }
                                    });
                                }} variant="contained" color="primary"  className={classes.button}>{store.getState().translation.dictionary["cancel"]}
                                
                                </Button>
                            </Stack>
                        </form>

                    </Paper>
                </Modal>
            </ThemeProvider >
        </div >
    )
}

export function EditForm() {
    // const classes = useStyles();
    const units = useSelector((state: Store) => state.units);

    const selectedUser = useSelector((state: Store) => state.selectedUsers[0])
    const classes = modalStyle(theme);
    const [userToUpdate, setUserToUpdate] = useState({ ...selectedUser });

    const submit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        Routes.User.update(userToUpdate);
        
        store.dispatch({
            type: ActionTypes.SET_SHOW_FORM,
            payload: { show: false, type: FormTypes.NEW }
        });
        store.dispatch({
            type: ActionTypes.SET_SELECTED_USERS,
            payload: [userToUpdate]
        });
    }

    return (
        <div>
            <ThemeProvider theme={theme}>
                <Modal
                    open
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={classes.root}
                >
                    <Paper className={classes.wrapper} elevation={3}>

                        <Grid container spacing={2} justifyContent="center">
                            <Grid item className={classes.header}>
                                <Typography>{store.getState().translation.dictionary["editUser"]}</Typography>
                            </Grid>
                        </Grid>

                        <form
                            className={classes.content}
                            onSubmit={(event) => submit(event)}
                        >
                            <TextField
                                required
                                margin="dense"
                                variant="outlined"
                                label={store.getState().translation.dictionary["firstName"]}
                                value={userToUpdate.firstName}
                                onChange={(event) => {
                                    let tempUser = { ...userToUpdate };
                                    tempUser.firstName = event.target.value;
                                    setUserToUpdate(tempUser);
                                }}
                            />
                            <TextField
                                required
                                margin="dense"
                                variant="outlined"
                                label={store.getState().translation.dictionary["lastName"]}
                                value={userToUpdate.lastName}
                                onChange={(event) => {
                                    let tempUser = { ...userToUpdate };
                                    tempUser.lastName = event.target.value;
                                    setUserToUpdate(tempUser);
                                }}
                            />
                            <TextField
                                required
                                margin="dense"
                                variant="outlined"
                                label={store.getState().translation.dictionary["email"]}
                                value={userToUpdate.email}
                                onChange={(event) => {
                                    let tempUser = { ...userToUpdate };
                                    tempUser.email = event.target.value;
                                    setUserToUpdate(tempUser);
                                }}
                            />
                            <TextField
                                required
                                margin="dense"
                                variant="outlined"
                                label={store.getState().translation.dictionary["title"]}
                                value={userToUpdate.workTitle}
                                onChange={(event) => {
                                    let tempUser = { ...userToUpdate };
                                    tempUser.workTitle = event.target.value;
                                    setUserToUpdate(tempUser);
                                }}
                            />
                            <FormControl fullWidth>
                                <InputLabel >{store.getState().translation.dictionary["unit"]}</InputLabel>
                                <Select
                                    // required
                                    MenuProps={{
                                        MenuListProps: {
                                        className: classes.gridList, // Apply styles to the ul element
                                        },
                                    }}                                    
                                    margin="dense"
                                    value={userToUpdate.unit.id}
                                    label={store.getState().translation.dictionary["unit"]}
                                    onChange={(event) => {
                                        let tempUser = { ...userToUpdate };
                                        tempUser.unit.id = event.target.value;
                                        tempUser.unit.name = units.find(x => x.unitId === tempUser.unit.id)?.name || "";
                                        setUserToUpdate(tempUser);
                                        //setNewUser(tempUser);
                                    }}
                                >
                                    {units.filter(unit => unit.active === 1).sort((u1, u2)=> (u1.name > u2.name) ? 1 : (u2.name > u1.name) ? -1 : 0).map((unit) => (
                                        <MenuItem key={unit.unitId} value={unit.unitId} >
                                            {unit.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <br />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={userToUpdate.unitSensor === 1 ? true : false}
                                        icon={<CheckBoxOutlineBlankIcon color="action" />}
                                        checkedIcon={<CheckBoxIcon color="action" />}
                                        onChange={(event) => {
                                            let tempUser = { ...userToUpdate };
                                            tempUser.unitSensor = event.target.checked ? 1 : 0;
                                            setUserToUpdate(tempUser);
                                        }}
                                    />
                                }
                                label={store.getState().translation.dictionary["sensor"]}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={userToUpdate.active === 1 ? true : false}
                                        icon={<CheckBoxOutlineBlankIcon color="action" />}
                                        checkedIcon={<CheckBoxIcon color="action" />}
                                        onChange={(event) => {
                                            let tempUser = { ...userToUpdate };
                                            tempUser.active = event.target.checked ? 1 : 0;
                                            setUserToUpdate(tempUser);
                                        }}
                                    />
                                }
                                label={store.getState().translation.dictionary["active"]}
                            />

                            <Stack sx={{ mt: 2 }} direction="row" justifyContent="center" spacing={2}>
                                <Button type="submit" variant="contained" className={classes.button}>{store.getState().translation.dictionary["save"]}</Button>

                                <Button onClick={() => {
                                    store.dispatch({
                                        type: ActionTypes.SET_SHOW_FORM,
                                        payload: { show: false, type: FormTypes.NEW }
                                    });
                                }} variant="contained" className={classes.button}>{store.getState().translation.dictionary["cancel"]}</Button>
                            </Stack>

                        </form>
                    </Paper>
                </Modal>
            </ThemeProvider >
        </div >
    )
}