import { makeStyles, Toolbar } from "@material-ui/core";
import { ThemeProvider, Modal, Paper, Grid, TextField, Button, Typography, Switch, FormControlLabel, Divider, Stack } from "@mui/material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FormEvent, useState } from "react";
import { useSelector } from "react-redux";
import { ActionTypes } from "../../redux/actionTypes";
import { store } from "../../redux/storeStates/store";
import { Store } from "../../redux/storeStates/StoreStates";
import defaultEntities from "../../utils/EntityUtils";
import { theme } from "../common/Theme";
import { FormTypes } from "../../interfaces/ui/Enums";
import Routes from "../../data/Routes";

interface Props {
    FormType: string
}

const modalStyle = makeStyles({
    root: {
        alignContent: "center",
        margin: "auto",
        marginLeft: "50%",
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "300px"
    },
    wrapper: {
        padding: "14px"
    },
    header: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    content: {
        paddingTop: "18px",
        paddingBottom: "18px",
    },
    footer: {
    },
    button: {
        paddingLeft: '5px',
        paddingRight: '5px',
        borderRadius: '5px',
        backgroundColor: '#555',
        color: '#fff',
        '&:hover': {
        backgroundColor: '#fff',
        color: '#555'}
    },
    gridList: {
        display: 'grid',
    }
})

export default function UnitForm(props: Props) {
    if (props.FormType === FormTypes.NEW) {
        return NewForm();
    }
    else if (props.FormType === FormTypes.EDIT) {
        return EditForm();
    }
    else return <></>;
}

export function NewForm() {
    // const classes = useStyles();
    const organization = useSelector((state: Store) => state.organization);
    const classes = modalStyle(theme);
    const units = useSelector((state: Store) => state.units);
    const [newUnit, setNewUnit] = useState({ ...defaultEntities.defaultUnit });
    const [formAutoClose, setModalAutoClose] = useState(true);

    const submit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        Routes.Unit.add(newUnit);
        // store.dispatch({
        //     type: ActionTypes.ADD_UNIT,
        //     payload: newUnit
        // })
        if (formAutoClose) {
            store.dispatch({
                type: ActionTypes.SET_SHOW_FORM,
                payload: { show: false, type: FormTypes.NEW }
            });
        }
        setNewUnit({ ...defaultEntities.defaultUnit });
    }

    return (
        <div>
            <ThemeProvider theme={theme}>
                <Modal
                    disableEnforceFocus
                    open
                    className={classes.root}
                >
                    <Paper className={classes.wrapper} elevation={3}>

                        <Grid container spacing={2} justifyContent="center">
                            <Grid item >
                                <Typography>{store.getState().translation.dictionary["addUnit"]}</Typography>
                                <Divider variant="fullWidth" />
                            </Grid>
                            {/* <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={!formAutoClose}
                                            onChange={() => setModalAutoClose(!formAutoClose)}
                                        ></Switch>}
                                    label="Håll kvar fönster"
                                />
                            </Grid> */}
                        </Grid>
                        <form
                            className={classes.content}
                            onSubmit={(event) => submit(event)}
                        >
                            <TextField
                                required
                                margin="dense"
                                variant="outlined"
                                label={store.getState().translation.dictionary["name"]}
                                value={newUnit.name}
                                onChange={(event) => {
                                    let tempUnit = { ...newUnit };
                                    tempUnit.name = event.target.value;
                                    setNewUnit(tempUnit);
                                }}
                            />
                            <TextField
                                required
                                margin="dense"
                                variant="outlined"
                                label={store.getState().translation.dictionary["description"]}
                                value={newUnit.description}
                                onChange={(event) => {
                                    let tempUnit = { ...newUnit };
                                    tempUnit.description = event.target.value;
                                    setNewUnit(tempUnit);
                                }}
                            />
                            <TextField
                                required
                                margin="dense"
                                variant="outlined"
                                label={store.getState().translation.dictionary["size"]}
                                type={"number"}
                                value={newUnit.size}
                                onChange={(event) => {
                                    let tempUnit = { ...newUnit };
                                    tempUnit.size = parseInt(event.target.value);
                                    setNewUnit(tempUnit);
                                }}
                            />
                            <FormControl fullWidth>

                                <InputLabel >{store.getState().translation.dictionary["parentUnit"]}</InputLabel>
                                <Select
                                    MenuProps={{
                                        MenuListProps: {
                                        className: classes.gridList, // Apply styles to the ul element
                                        },
                                    }}                                    margin="dense"
                                    value={newUnit.parentUnit.id}
                                    label={store.getState().translation.dictionary["unit"]}
                                    onChange={(event) => {
                                        let tempUnit = { ...newUnit };
                                        tempUnit.parentUnit.id = event.target.value;
                                        tempUnit.parentUnit.name = units.find(x => x.unitId === tempUnit.parentUnit.id)?.name || "";
                                        setNewUnit(tempUnit);
                                    }}
                                >
                                    <MenuItem key="Ingen" value="0">{organization.name}</MenuItem>
                                    {units.filter(unit => unit.active === 1).sort((u1, u2)=> (u1.name > u2.name) ? 1 : (u2.name > u1.name) ? -1 : 0).map((unit) => (
                                        <MenuItem key={unit.unitId} value={unit.unitId} >
                                            {unit.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <Stack sx={{ mt: 2 }} direction="row" justifyContent="center" spacing={2}>
                                <Button type="submit" variant="contained" className={classes.button}>{store.getState().translation.dictionary["save"]}</Button>

                                <Button onClick={() => {
                                    store.dispatch({
                                        type: ActionTypes.SET_SHOW_FORM,
                                        payload: { show: false, type: FormTypes.NEW }
                                    });
                                }} variant="contained" className={classes.button}>{store.getState().translation.dictionary["cancel"]}</Button>
                            </Stack>
                        </form>
                    </Paper>
                </Modal>
            </ThemeProvider >
        </div >
    )
}

export function EditForm() {
    // const classes = useStyles();
    const selectedUnit = useSelector((state: Store) => state.selectedUnits[0]);
    const classes = modalStyle(theme);
    const [unitToUpdate, setUnitToUpdate] = useState({ ...selectedUnit });

    const submit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // store.dispatch({
        //     type: ActionTypes.UPDATE_UNITS,
        //     payload: [unitToUpdate]
        // })
        Routes.Unit.update(unitToUpdate);

        store.dispatch({
            type: ActionTypes.SET_SHOW_FORM,
            payload: { show: false, type: FormTypes.NEW }
        });
        store.dispatch({
            type: ActionTypes.SET_SELECTED_UNITS,
            payload: [unitToUpdate]
        });
    }

    return (
        <div>
            <ThemeProvider theme={theme}>
                <Modal
                    open
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={classes.root}
                >
                    <Paper className={classes.wrapper} elevation={3}>

                        <Grid container spacing={2} justifyContent="center">
                            <Grid item className={classes.header}>
                                <Typography>{store.getState().translation.dictionary["editUnit"]}</Typography>
                            </Grid>
                        </Grid>

                        <form className={classes.content} onSubmit={(event) => submit(event)}
                        >
                            <TextField
                                required
                                margin="dense"
                                variant="outlined"
                                label={store.getState().translation.dictionary["name"]}
                                value={unitToUpdate.name}
                                onChange={(event) => {
                                    let tempUnit = { ...unitToUpdate };
                                    tempUnit.name = event.target.value;
                                    setUnitToUpdate(tempUnit);
                                }}
                            />
                            <TextField
                                required
                                margin="dense"
                                variant="outlined"
                                label={store.getState().translation.dictionary["description"]}
                                value={unitToUpdate.description}
                                onChange={(event) => {
                                    let tempUnit = { ...unitToUpdate };
                                    tempUnit.description = event.target.value;
                                    setUnitToUpdate(tempUnit);
                                }}
                            />
                            <TextField
                                required
                                margin="dense"
                                variant="outlined"
                                label={store.getState().translation.dictionary["size"]}
                                value={unitToUpdate.size}
                                type={"number"}
                                onChange={(event) => {
                                    let tempUnit = { ...unitToUpdate };
                                    tempUnit.size = parseInt(event.target.value);
                                    setUnitToUpdate(tempUnit);
                                }}
                            />

                            <Stack sx={{ mt: 2 }} direction="row" justifyContent="center" spacing={2}>
                                <Button type="submit" variant="contained" className={classes.button}>{store.getState().translation.dictionary["save"]}</Button>

                                <Button onClick={() => {
                                    store.dispatch({
                                        type: ActionTypes.SET_SHOW_FORM,
                                        payload: { show: false, type: FormTypes.NEW }
                                    });
                                }} variant="contained" className={classes.button}>{store.getState().translation.dictionary["cancel"]}</Button>
                            </Stack>
                        </form>
                    </Paper>
                </Modal>
            </ThemeProvider >
        </div >
    )
}