import { makeStyles } from "@material-ui/core";
import { theme } from "../common/Theme";
import { Card, TextField, Typography, Button, Tooltip, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { ReactNode, useState } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import ImpactFactor from "../../interfaces/entities/ImpactFactor";
import ImpactFactorConfiguration from "../../interfaces/entities/ImpactFactorConfiguration";
import FactorItem from "./FactorItem";
import { v4 as uuid } from 'uuid';
import { store } from "../../redux/storeStates/store";

const modalStyle = makeStyles({
    root: {
        alignContent: "center",
        margin: "auto",
        marginLeft: "50%",
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "300px"
    },
    wrapper: {
        padding: "14px"
    },
    header: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    content: {
        paddingTop: "18px",
        paddingBottom: "18px",
    },
    footer: {
    },
    gridList: {
        display: 'grid',
    },
    button: {
        paddingLeft: '5px',
        paddingRight: '5px',
        borderRadius: '5px',
        backgroundColor: '#555',
        color: '#fff',
        '&:hover': {
        backgroundColor: '#fff',
        color: '#555'}
    }
})

interface Props {
    name: string;
    title: string;
    description: string;
    editMode: boolean;
    onChange?: (property: string, value: string) => void;
    impactFactorConfigurations?: ImpactFactorConfiguration[];
    impactFactors?: ImpactFactor[];
    content?: ReactNode;
    onAddRemove?: (fc: ImpactFactorConfiguration) => void;
    onUpdate?: (fc: ImpactFactorConfiguration) => void;
    onDragEnd?: (result: any) => void;
}

/**
 * @param name
 * The name of the section.
 * @param title
 * The title.
 * @param description
 * The description.
 * @param editMode
 * Tells us if the section should be editable or not.
 * 
 * --------
 * 
 * Optional parameters:
 * @param onChange
 * A singal that emitts the property name and value that triggers when we change either the title or description.
 * @param impactFactorConfigurations
 * Lists an array of impactFactorConfigurations.
 * @param impactFactors
 * Array of the pre-defined impactFactors that we can chose from.
 * @param content
 * Displays any node in the bottom of the section.
 * @param onAddRemove
 * A FactorConfiguration requests to either be added or removed.
 * @param onUpdate
 * A FactorConfiguration has been updated.
 * @param onDragEnd
 * An item has been dropped in the drag-and-drop area.
 */
export const Section: React.FC<Props> = (props) => {
    const [selectedImpactFactorIndex, setSelectedImpactFactorIndex] = useState<number | ''>('');
    const classes = modalStyle(theme);

    return (
        <div style={{}}>
            <Typography style={{ fontWeight: "bold", marginBottom: 4 }}>{props.name}</Typography>

            <Card style={{ padding: 8, marginBottom: 16, backgroundColor: "#EEEEEE" }}>
                {!props.editMode ?
                    <div>
                        <Typography>{props.title}</Typography>
                        <Typography variant="body2">{props.description}</Typography>
                    </div>
                    :
                    <div>
                        <TextField style={{ display: "flex" }}
                            required
                            label={store.getState().translation.dictionary["title"]}
                            value={props.title}
                            onChange={(e) => {
                                if (props.onChange) {
                                    props.onChange("title", e.target.value);
                                }
                            }}
                        />
                        <TextField style={{ display: "flex" }}
                            disabled
                            multiline
                            label={store.getState().translation.dictionary["description"]}
                            value={props.description}
                            onChange={(e) => { if (props.onChange) props.onChange("description", e.target.value) }}
                        />
                    </div>
                }

                {props.impactFactorConfigurations ?
                    <div>
                        <Typography style={{ marginTop: 8, marginBottom: 4, fontWeight: "bold" }}>{store.getState().translation.dictionary["impactFactors"]}</Typography>

                        <DragDropContext onDragEnd={(result: DropResult) => { if (props.onDragEnd) props.onDragEnd(result) }}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {
                                            props.impactFactorConfigurations?.map(
                                                (impactFactorConfiguration: ImpactFactorConfiguration, index) => {
                                                    return <FactorItem
                                                        key={impactFactorConfiguration.impactFactorConfigurationId}
                                                        index={index}
                                                        impactFactorConfiguration={impactFactorConfiguration}
                                                        editMode={props.editMode}
                                                        onRemove={(fc) => { if (props.onAddRemove) props.onAddRemove(fc) }}
                                                        onUpdate={(fc) => { if (props.onUpdate) props.onUpdate(fc) }}
                                                    />
                                                }
                                            )
                                        }
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>

                        {props.editMode ?
                            <div style={{ display: "flex" }}>
                                <FormControl style={{ marginLeft: "auto", minWidth: 200 }}>
                                    <InputLabel id="factor-menu-label">{store.getState().translation.dictionary["impactFactors"]}</InputLabel>
                                    <Select
                                        MenuProps={{
                                            MenuListProps: {
                                            className: classes.gridList, // Apply styles to the ul element
                                            },
                                        }}                                                                            
                                        labelId="factor-menu-label"
                                        value={selectedImpactFactorIndex}
                                        onChange={(e) => setSelectedImpactFactorIndex(Number(e.target.value))}
                                        style={{ marginRight: 16 }}
                                        disabled={props.impactFactors && props.impactFactors.length < 1}
                                    >
                                        {props.impactFactors?.map((impactFactor, index) => {
                                            return <MenuItem value={index} key={"factorMenuItem" + index}>{impactFactor.name}</MenuItem>
                                        })

                                        }
                                    </Select>
                                </FormControl>

                                <Tooltip title={store.getState().translation.dictionary["selectImpactFactor"]}>
                                    <Button className={classes.button}
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AddCircle />}
                                        disabled={selectedImpactFactorIndex !== undefined && selectedImpactFactorIndex < 0}
                                        style={{ marginTop: "auto" }}
                                        onClick={() => {
                                            if (props.onAddRemove && props.impactFactors && typeof (selectedImpactFactorIndex) === "number") {
                                                // props.onAddRemove({ impactFactorConfigurationId: "fc" + (props.impactFactors[selectedImpactFactorIndex].impactFactorId + 1), impactFactor: props.impactFactors[selectedImpactFactorIndex], displayName: "", commentVisible: 0 });
                                                props.onAddRemove({ impactFactorConfigurationId: uuid(), impactFactor: props.impactFactors[selectedImpactFactorIndex], displayName: "", commentVisible: 0 });
                                                setSelectedImpactFactorIndex('');
                                            }
                                        }}
                                    >
                                        {store.getState().translation.dictionary["add"]}
                                    </Button>
                                </Tooltip>
                            </div>
                            : null
                        }
                    </div>
                    : null
                }
                {
                    props.content ? props.content : null
                }
            </Card>
        </div>
    );
}