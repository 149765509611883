import { FormControlLabel, FormGroup, Radio } from "@material-ui/core";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Store } from "../redux/storeStates/StoreStates";
import { Accordion, AccordionSummary, AccordionDetails, Checkbox, Stack, TextField, Divider, RadioGroup, Paper, Typography, Grid, FormLabel } from "@mui/material";
import Unit from "../interfaces/entities/Unit";
import OrganizationTreeView from "./common/TreeView";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Statusbar } from "./charts/Statusbar";
import { ImpactFactorRadar } from "./charts/ImpactFactorRadar";
import { AssessmentValuesChart } from "./charts/AssessmentValuesChart";
import { ReportFrequenciesBarChart } from "./charts/ReportFrequenciesBarChart";
import { ImpactFactorStackedAreaChart } from "./charts/ImpactFactorStackedAreaChart";
import LinearProgressBar from "./common/LinearProgressBar";
import PrognosisChart from "./charts/prognosis/PrognosisChart";
import { store } from '../redux/storeStates/store';
import { makeStyles } from '@material-ui/styles';
import { DashboardCheckboxFilter } from "../interfaces/entities/DashboardCheckboxFilter";
import { ActionTypes } from "../redux/actionTypes";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import BarChartPosNeg from "./charts/BarChartPosNeg";
import Sentiment from "../interfaces/entities/Sentiment";

const useStyles = makeStyles({
    gridItem: {
      borderRight: '1px solid darkgray',
      padding: '5px',
      backgroundColor: '#f5f5f5',
    },
    gridItemHr: {
        paddingRight: '5px',
        height: '100%', 
        paddingTop: '0px !important',
      },
      headerSetting: {
        height: '100%', 
        backgroundColor: '#f5f5f5',
        marginLeft: '0px',
        marginTop: '0px'
    },
    gridItemCharContainer: {
      height: '100%', 
      padding: '20px !important',
    },
    checkboxSetting: {
        marginTop: '8px'
    },
  });

  interface Data {
    name: string;
    value: number;
    }

export default function Dashboard() {
    const classes = useStyles();
    const units = useSelector((state: Store) => state.units);

    const [selectedUnits, setSelectedUnits] = useState<Unit[]>([...units]);
    const translation = useSelector((state: Store) => state.translation);
    const textAnalysisResult = useSelector((state: Store) => state.textAnalysisResult);

    const checkboxFilter = useSelector((state: Store) => state.dashboardCheckboxFilter);

    // const data = [
    //     { name: 'A', value: 30 },
    //     { name: 'B', value: -20 },
    //     { name: 'C', value: 45 },
    //     { name: 'D', value: -60 },
    //     { name: 'E', value: 20 },
    //     { name: 'F', value: -90 },
    //     { name: 'G', value: 55 },
    //   ];

    function getData() {
        let sentimentScoreCutOff = 0.5;
        console.log("In BARUnits3: ", selectedUnits);
        let data: Data[] = [];
        if(units.length > 0) {
            console.log("In BARUnits: ", units);
            console.log("textAnalysisResult: ", textAnalysisResult);
            const sentiments = textAnalysisResult.reduce((map, obj) => {
                console.log("obj: ", obj);
                if(checkboxFilter.ImpactFactor) {
                    let tempSentiment = {score: 0} as Sentiment;
                    let count = 0;
                    let tempSentimentType = 0;
                    obj.impactfactorCommentsSentiment.forEach(sentiment => {
                        if(sentiment.sentiment === "POSITIVE") {
                            tempSentiment.score += sentiment.score;
                            count += 1;
                            tempSentimentType += 1;
                        } else if (sentiment.sentiment === "NEGATIVE") {
                            tempSentiment.score += sentiment.score;
                            count += 1;
                            tempSentimentType -= 1;
                        }
                        tempSentiment.sentiment = sentiment.sentiment;
                    });
                    tempSentiment.score = tempSentiment.score/count;
                    tempSentiment.sentiment = tempSentimentType > 0 ? "POSITIVE" : "NEGATIVE";
                    // tempSentiment.id = obj.assessmentId;
                    map[obj.assessmentId] = tempSentiment;
                    console.log("tempSentiment: ", tempSentiment);
                } else if(checkboxFilter.PrognosisShort) {
                    map[obj.assessmentId] = obj.prognosisShorttermCommentSentiment;
                } else if(checkboxFilter.PrognosisLong) {
                    map[obj.assessmentId] = obj.prognosisLongtermCommentSentiment;
                } else {
                    map[obj.assessmentId] = obj.summarizedSentiment;
                }
                return map;
            }, {} as { [key: string]: Sentiment });
            console.log("Sentiments: ", sentiments);
            console.log("Object.keys(sentiments)", Object.keys(sentiments));
            let dates: string[] = [];
            let values: Map<string, Sentiment[]> = new Map<string, Sentiment[]>();
            console.log("CheckboxFilter.UserAssessments: ", checkboxFilter.UserAssessments);
            if(checkboxFilter.UserAssessments) {
                selectedUnits.forEach(unit => {
                    unit.users.forEach(user => {
                        user.userAssessmentEvents.forEach(assessmentEvent => {
                            if(assessmentEvent.userAssessment) {
                                let plannedDate = assessmentEvent.plannedDate;
                                let assessmentId = assessmentEvent.userAssessment.assessmentId;
                                // console.log("AssessmentId: ", assessmentId);  
                                // console.log("Sentiment: ", sentiments[assessmentId]);     
                                if (!dates.includes(plannedDate)) {
                                    dates.push(plannedDate);
                                    values.set(plannedDate, [sentiments[assessmentId]]);
                                } else {
                                    values.get(plannedDate)?.push(sentiments[assessmentId]);
                                }
                            }
                        });
                    });
                });
            }
            console.log("Values: ", values);
            console.log("Dates: ", dates);
            let filtered_dates = dates.filter(date => moment(date).isBetween(checkboxFilter.SelectedDates.from, checkboxFilter.SelectedDates.to))
                    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
            console.log("Filtered Dates: ", filtered_dates);
            filtered_dates.forEach(date => {
                let sum = 0;
                values.get(date)?.forEach(sentiment => {
                    console.log("Sentiment: ", sentiment);
                        if(sentiment && sentiment.score > sentimentScoreCutOff) {
                            if(sentiment.sentiment==="POSITIVE") {
                                sum += 1;
                            } else if(sentiment.sentiment==="NEGATIVE") {
                                sum -= 1;
                            }
                        }
                    });
                    data.push({ name: date.substring(0,10), value: sum });
                });
            }     
            console.log("Data: ", data);   
            return data;
    }
     
    function setProps() {
        return ({
            units: selectedUnits,
            from: checkboxFilter.SelectedDates.from,
            to: checkboxFilter.SelectedDates.to, //selectedDates.to,
            includeUnits: checkboxFilter.UnitAssessments,
            includeUsers: checkboxFilter.UserAssessments,
            calculationMode: checkboxFilter.Mode,
        });
    }

    function getInactiveUnits() {
        return units.filter(unit => unit.active === 0);
    }

    return (
            <Grid container spacing={2}>
                <Grid container spacing={2} className={classes.headerSetting}>
                    <Grid item xs={2}>
                    <RadioGroup row>
                        {/* <FormLabel component="legend">{translation.dictionary["dataFiltering"]}</FormLabel> */}
                        <FormControlLabel control={<Radio color="default" checked={checkboxFilter.UnitAssessments}
                            // onChange={ev => setCheckboxFilter({ ...checkboxFilter, UnitAssessments: ev.target.checked, UserAssessments: !ev.target.checked })} />}
                            onChange={ev => store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, UnitAssessments: ev.target.checked, UserAssessments: !ev.target.checked }})} />}
                            label={translation.dictionary["unitAssessments"]} />
                        <FormControlLabel control={<Radio color="default" checked={checkboxFilter.UserAssessments}
                            // onChange={ev => setCheckboxFilter({ ...checkboxFilter, UserAssessments: ev.target.checked, UnitAssessments: !ev.target.checked })} />}
                            onChange={ev => store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, UserAssessments: ev.target.checked, UnitAssessments: !ev.target.checked }})} />}
                            label={translation.dictionary["userAssessments"]} />
                    </RadioGroup>
                    </Grid>
                    <Grid item xs={2} className={classes.checkboxSetting}>
                        {/* <FormLabel component="legend">{translation.dictionary["dataFiltering"]}</FormLabel> */}
                        <FormGroup row>
                            <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkboxFilter.Min} onChange={ev => store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, Min: ev.target.checked }})} />} label={translation.dictionary["min"]} />
                            <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkboxFilter.Max} onChange={ev => store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, Max: ev.target.checked }})} />} label={translation.dictionary["max"]} />
                            <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon color="action" />} checkedIcon={<CheckBoxIcon color="action" />} checked={checkboxFilter.Main} onChange={ev => store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, Max: ev.target.checked }})} />} label={translation.dictionary["main"]} />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={2}>
                        <RadioGroup row>
                            <FormControlLabel control={<Radio color="default" checked={checkboxFilter.Mode}
                                // onChange={ev => setCheckboxFilter({ ...checkboxFilter, Mode: ev.target.checked, Mean: !ev.target.checked })} />}
                                onChange={ev => store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, Mode: ev.target.checked, Mean: !ev.target.checked }})} />}
                                label={translation.dictionary["typeValue"]} />
                            <FormControlLabel control={<Radio color="default" checked={checkboxFilter.Mean}
                                // onChange={ev => setCheckboxFilter({ ...checkboxFilter, Mean: ev.target.checked, Mode: !ev.target.checked })} />}
                                onChange={ev => store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, Mean: ev.target.checked, Mode: !ev.target.checked }})} />}
                                label={translation.dictionary["averageValue"]} />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <RadioGroup row>
                            <FormControlLabel control={<Radio color="default" checked={checkboxFilter.TotalSentiment}
                                onChange={ev => store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, TotalSentiment: ev.target.checked,  ImpactFactor: !ev.target.checked, PrognosisLong: !ev.target.checked, PrognosisShort: !ev.target.checked }})} />}
                                label={translation.dictionary["sentimentTotal"]} />
                            <FormControlLabel control={<Radio color="default" checked={checkboxFilter.ImpactFactor}
                                onChange={ev => store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, ImpactFactor: ev.target.checked, PrognosisLong: !ev.target.checked, PrognosisShort: !ev.target.checked, TotalSentiment: !ev.target.checked}})} />}
                                label={translation.dictionary["impact_factor"]} />
                            <FormControlLabel control={<Radio color="default" checked={checkboxFilter.PrognosisLong}
                                onChange={ev => store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, PrognosisLong: ev.target.checked, ImpactFactor: !ev.target.checked, PrognosisShort: !ev.target.checked, TotalSentiment: !ev.target.checked }})} />}
                                label={translation.dictionary["prognosisShortterm"]} />
                            <FormControlLabel control={<Radio color="default" checked={checkboxFilter.PrognosisShort}
                                onChange={ev => store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, PrognosisShort: ev.target.checked, ImpactFactor: !ev.target.checked, PrognosisLong: !ev.target.checked, TotalSentiment: !ev.target.checked }})} />}
                                label={translation.dictionary["prognosisLongterm"]} />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={2}>
                    <TextField
                        variant="outlined"
                        label={translation.dictionary["from"]}
                        //value={selectedDates.from}
                        value={checkboxFilter.SelectedDates.from}
                        type="date"
                        inputProps={{ max: moment(moment.now()).format("YYYY-MM-DD") }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => {
                            store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, SelectedDates: { ...checkboxFilter.SelectedDates, from: event.target.value }}});
                            // setSelectedDates({ ...selectedDates, from: event.target.value });
                        }}
                    />
                    <TextField
                        variant="outlined"
                        label={translation.dictionary["to"]}
                        // value={selectedDates.to}
                        value={checkboxFilter.SelectedDates.to}
                        type="date"
                        inputProps={{ max: moment(moment.now()).format("YYYY-MM-DD") }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => {
                            store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, SelectedDates: { ...checkboxFilter.SelectedDates, to: event.target.value }}});
                            // setSelectedDates({ ...selectedDates, to: event.target.value });
                        }}
                    />
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.gridItemHr}>
                <hr />
                </Grid>
                <Grid container spacing={2} className={classes.gridItemCharContainer}>
                    <Grid item xs={2} className={classes.gridItem}>
                        <OrganizationTreeView
                                // selected={units.length > 0 ? selectedIds : ["1"]}
                                // selected={selectedIds}
                                selected={checkboxFilter.SelectedIds}
                                feedback={(selectedIds: string[]) => {
                                    // setSelectedIds(selectedIds.length > 0 ? selectedIds : ["1"]);
                                    store.dispatch({type: ActionTypes.SET_DASHBOARD_FILTER, payload:{ ...checkboxFilter, SelectedIds: selectedIds.length > 0 ? selectedIds : ["1"]}});
                                    // setSelectedIds(selectedIds);
                                    let selected = units.filter(x => selectedIds.includes(x.unitId));
                                    if (selected.length > 0) {
                                        selected = selected.concat(getInactiveUnits()); //Include inactive units to get historic data
                                        setSelectedUnits(selected);
                                    }
                                }}
                            />
                    </Grid>
                    <Grid item xs={10} className={classes.gridItem}>
                    {/* <Stack spacing={2}> */}
                        <Statusbar {...setProps()} />
                        <div style={{ width: '100%' }}>
                            <BarChartPosNeg {...setProps()} data={getData()} height={400} />
                        </div>
                        <AssessmentValuesChart {...setProps()} checkboxFilter={checkboxFilter} />

                        <ImpactFactorStackedAreaChart {...setProps()} />

                        {/* <PrognosisChart {...setProps()} /> */}

                        <ReportFrequenciesBarChart {...setProps()} />
                        {/* <ImpactFactorRadar {...setProps()} /> */}
                    {/* </Stack> */}
                    </Grid>
                </Grid>
            </Grid>
    )
}